
/* Home Page Container */
.home-page-container {
  padding: 16px;
  height: calc(100vh - 112px);
  position: relative;
  max-width: 900px;
  margin: 0 auto;
}

/* Responses Container */
.responses-container {
  width: 100% !important;
  overflow-y: auto;
  max-height: 95%;
  padding-bottom: 2px;

  /* Hide scrollbar */
   -ms-overflow-style: none; /*for Internet Explorer, Edge */
   scrollbar-width: none; /*for Firefox */
}

.responses-container::-webkit-scrollbar {
  display: none;  /* for Chrome, Safari, and Opera */
}

/* Prompt Container */
.prompt-container {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

/* No Database Connected Container */
.no-db-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar Styling */
.MuiAvatar-root {
  background-color: #1976d2;
}

/* User Message Styling */
.user-message {
  background: #efefef !important;
  color: #333 !important;
  padding: 10px;
  border-radius: 20px 0px 20px 20px !important;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 75%;
  min-width: 1%;
  width: fit-content;
}

/* Bot Message Styling */
.bot-message {
  background-color: #00B0FF;
  background: #00B0FF !important;
  color: white !important;
  padding: 10px;
  border-radius: 0px 20px 20px 20px !important;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 75%;
  min-width: 1%;
  width: fit-content;

}


/* Style for markdown content */
.markdown-content {
  overflow-x: auto; /* Allow horizontal scrolling */
  max-width: 100%; /* Ensure it fits within the container */
  margin: 0 auto; /* Center the content */
}
/* Style for <pre> and <code> blocks inside markdown */
.markdown-content pre {
  white-space: pre-wrap; /* Preserve whitespace and wrap lines */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  padding: 10px;
  border-radius: 4px;
  background: #f5f5f5; /* Light background for code blocks */
  margin: 0;
}

.markdown-content code {
  font-family: monospace; /* Ensure monospaced font for code */
  color: #d73a49; /* Highlight color for code */
}

/* Optional: Style for syntax highlighting with specific classes */
.hljs {
  display: block;
  overflow-x: auto; /* Allow horizontal scrolling for long lines */
  padding: 0.5em; /* Add some padding */
  background: #f5f5f5; /* Light background for highlighted code */
  border-radius: 4px; /* Rounded corners for the block */
}

/* Specific styles for different syntax elements */
.hljs-keyword {
  color: #d73a49; /* Example color for keywords */
}

.hljs-string {
  color: #032f62; /* Example color for strings */
}

.hljs-operator {
  color: #d73a49; /* Example color for operators */
}

/* Table styles within markdown content */
.markdown-content table {
  width: 100%; /* Ensure the table takes full width */
  border-collapse: collapse; /* Remove space between borders */
  background: #ffffff; /* Background color for the table */
  border: 1px solid #e0e0e0; /* Border color for the table */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  border-radius: 4px; /* Rounded corners for the table */
  margin: 16px 0; /* Margin around the table for spacing */
}

.markdown-content th, .markdown-content td {
  padding: 16px; /* Padding inside table cells */
  border-bottom: 1px solid #e0e0e0; /* Border color for table cells */
  text-align: left; /* Align text to the left in cells */
  color: #333; /* Text color for table cells */
}

.markdown-content th {
  background-color: #f5f5f5; /* Background color for table headers */
  font-weight: 600; /* Make header text semi-bold */
  color: #333; /* Text color for table headers */
}

.markdown-content tr:hover {
  background-color: #f1f1f1; /* Background color for table rows on hover */
}

.markdown-content a {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline from links */
}

.markdown-content a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.markdown-content code {
  background: #f5f5f5; /* Background color for code blocks */
  color: #d63384; /* Text color for code */
  padding: 2px 4px; /* Padding inside code blocks */
  border-radius: 4px; /* Rounded corners for code blocks */
}

.markdown-content pre {
  background: #f5f5f5; /* Background color for preformatted text */
  color: #d63384; /* Text color for preformatted text */
  padding: 8px; /* Padding inside preformatted text */
  border-radius: 4px; /* Rounded corners for preformatted text */
  overflow-x: auto; /* Allow horizontal scrolling if content overflows */
}
